import { useMutation, useQuery } from "react-query";
import { ServerError, PageFilter, Count, NameAndId, useAxios, LoginResponse, getCaptchaToken } from "suregifts-component-lib";
import { BusinessTokenDto } from "./auth";

export const useRegister = () => {
  var axios = useAxios();
  return useMutation<BusinessTokenDto, ServerError, RegisterModel>(async (model) => {
    return axios.post("/business/api/register", model, { headers: { Captcha: await getCaptchaToken() } });
  });
};
export const useAddAccount = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, AddAccountModel>((model) => {
    return axios.post("/business/api/add-account", model);
  });
};

export const useGetMyBusinesses = (filter: PageFilter, onSuccess?: (data) => void) => {
  const axios = useAxios();
  return useQuery<Count<BusinessData>, ServerError>({
    queryKey: ["my-businesses", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/business/api/businesses", {
        params: filter,
      }),
    onSuccess,
  });
};
export const useGetBusinessInfo = () => {
  const axios = useAxios();
  return useQuery<BusinessInfoData, ServerError>({
    queryKey: ["business-info"],
    queryFn: () => axios.get("/business/api/business-info", {}),
    keepPreviousData: true,
  });
};
export const useUpdateBusiness = () => {
  var axios = useAxios();
  return useMutation<BusinessInfoData, ServerError, UpdateBusinessModel>((model) => {
    return axios.post("/business/api/businesses", model);
  });
};
export const useUpdateCredential = () => {
  var axios = useAxios();
  return useMutation<BusinessInfoData, ServerError, UpdateCredentialModel>((model) => {
    return axios.post("/business/api/credentials", model);
  });
};

export const useRefreshCredential = () => {
  var axios = useAxios();
  return useMutation<SecretData, ServerError, unknown>((d) => {
    return axios.post("/business/api/credentials/refresh", {
      model: 0,
    });
  });
};

export const useGetRoles = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["my-businesses"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/business/api/roles", {}),
  });
};
export const useGetManagers = (filter: PageFilter) => {
  const axios = useAxios();
  return useQuery<ManagerData[], ServerError>({
    queryKey: ["managers", filter],
    keepPreviousData: true,
    initialData: [],
    queryFn: () =>
      axios.get("/business/api/managers", {
        params: filter,
      }),
  });
};
export const useAddManager = () => {
  var axios = useAxios();
  return useMutation<BusinessInfoData, ServerError, AddManagerModel>((model) => {
    return axios.post("/business/api/managers", model);
  });
};
export const useEditManager = (managerId) => {
  var axios = useAxios();
  return useMutation<BusinessInfoData, ServerError, EditManagerModel>((model) => {
    return axios.post(`/business/api/managers/${managerId}`, model);
  });
};
export const useDeleteManager = () => {
  var axios = useAxios();
  return useMutation<BusinessInfoData, ServerError, any>((managerId) => {
    return axios.delete(`/business/api/managers/${managerId}`);
  });
};
export interface RegisterModel {
  rcNumber: string;
  name: string;
  industryId: string;
  email: string;
  password: string;
  confirmPassword: string;
  unit: string;
}
export interface BusinessData {
  id: string;
  roleId: string;
  role: string;
  unit: string;
  name: string;
  email: string;
  isActive: boolean;
  verified: boolean;
}
export interface UpdateBusinessModel {
  name: string;
  unit: string;
  website: string;
  address: string;
  rcNumber: string;
  employees: number;
  industryId: string;
  logo: string;
}
export interface BusinessInfoData {
  email: string;
  rcNumber: string;
  name: string;
  industryType: string;
  industryId: string;
  address: string;
  unit: string;
  logo: string;
  website: string;
  employees: number;
  credential: CredentialData;
  secret: string;
}
export interface UpdateCredentialModel {
  webHook: string;

  allowedIps: string;
}
export interface AddAccountModel {
  rcNumber: string;
  name: string;
  industryId: string;
  unit: string;
}

export interface CredentialData {
  allowedIps: string;
  key: string;
  hasSecret: boolean;
  isActive: boolean;
  webHook: string;
}
export interface ManagerData {
  dateAdded: Date;
  email: string;
  id: number;
  name: string;
  roleId: string;
  role: string;
}
export interface SecretData {
  secret: string;
  key: string;
}
export interface AddManagerModel {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
}
export interface EditManagerModel extends AddManagerModel {}
