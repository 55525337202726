import { Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { FormButton, FormInput, LoadingBox, PageHeaderTitle, useDocumentTitle } from "suregifts-component-lib";
import { UpdateCredentialModel, useGetBusinessInfo, useRefreshCredential, useUpdateCredential } from "../../services/business";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useBusinessContext } from "../../contexts/BusinessContext";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";

function DeveloperScreen() {
  useDocumentTitle("Developer");
  const [cookie] = useCookies(["APPLICATION_MODE"]);

  const { mutate: refreshCredential, isLoading: isRefreshing, data: secret } = useRefreshCredential();
  const { mutate: updateCredential, isLoading: isUpdating } = useUpdateCredential();
  const { data, isLoading } = useGetBusinessInfo();

  const handleRefresh = () => {
    refreshCredential({});
  };

  const submitForm = (model) => {
    updateCredential(model, {
      onSuccess: () => {
        toast.success("Information saved successfully");
      },
    });
  };
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm<UpdateCredentialModel>({
    defaultValues: { webHook: data?.credential.webHook, allowedIps: data?.credential.allowedIps },
  });
  const mode = useMemo(() => {
    const md: string = cookie.APPLICATION_MODE || "live";
    return `${md} MODE`.toUpperCase();
  }, []);
  return (
    <div>
      <PageHeaderTitle title="Developer" />
      <LoadingBox
        loading={isRefreshing}
        sx={{
          gap: "40px",
          display: "grid",
          marginTop: "40px",
          borderRadius: "30px",
          backgroundColor: "primary.main",
          padding: "40px 50px",
        }}
      >
        <Typography style={{ fontSize: "1.5rem", fontWeight: 700, position: "relative" }}>API Configuration - {mode}</Typography>

        <Stack spacing={2} sx={{}}>
          <Stack direction={"row"} spacing={2}>
            <FormInput disabled label="Public Key" value={data?.credential.key} endAdornment={<CopyIconButton value={data?.credential.key} />} />
            <FormInput label="Webhook Url" {...register("webHook")} helperText={errors.webHook?.message} />
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <FormInput disabled label="Secret Key" value={secret?.secret || "*******"} endAdornment={<CopyIconButton value={secret?.secret} />} />
            <FormInput
              label=<div>
                Allowed IP address(s). <i style={{ fontWeight: 400 }}>comma separated</i>{" "}
              </div>
              {...register("allowedIps")}
              helperText={errors.allowedIps?.message}
            />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <FormButton color="error" fullWidth sx={{ alignSelf: "flex-start" }} loading={isRefreshing} label="Generate Secret" onClick={handleRefresh} />
            <FormButton
              disabled={!isDirty}
              fullWidth
              sx={{ alignSelf: "flex-start" }}
              loading={isUpdating}
              label="Save Changes"
              onClick={handleSubmit(submitForm)}
            />
          </Stack>
        </Stack>
      </LoadingBox>
    </div>
  );
}
const CopyIconButton = ({ value = "" }) => {
  return (
    <ContentCopyIcon
      sx={{ cursor: "pointer" }}
      onClick={() => {
        navigator.clipboard.writeText(value);
        toast.success("Key copied!");
      }}
    />
  );
};

export default DeveloperScreen;
