import React, { CSSProperties, useState } from "react";
import {
  MainTable,
  MarginTop40Box,
  OrderStatusLabel,
  OrderStatusType,
  PageFilter,
  Pagination,
  RefundIcon,
  ResendIcon,
  SearchIcon,
  TableBody,
  dateOnly,
  dateTime,
  useDocumentTitle,
  useFilter,
} from "suregifts-component-lib";
import FilterSection from "./FilterSection";
import { IconButton, TableCell, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { OrderData, useExportOrderRecipients, useGetOrders, useResendOrder, useSendOrderExportToMail } from "../../../../services/order";
import { BusinessOrderType } from "../../../../shared/enums";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SendIcon from "@mui/icons-material/Send";
import EmailModal from "./EmailModal";

function OrderListScreen() {
  const navigate = useNavigate();
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" });
  const { data, isFetching } = useGetOrders(filter);
  const { mutate: resendOrder, isLoading: isResending } = useResendOrder();
  const { mutate: exportOrder, isLoading: isExporting } = useExportOrderRecipients();
  const [orderId, setOrderId] = useState<any>();
  useDocumentTitle("Orders");

  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const handleResend = (item: OrderData) => {
    resendOrder(item.id, {
      onSuccess: () => {
        toast.success("Vouchers resent successfully.");
      },
    });
  };
  const handleExport = (item: OrderData) => {
    exportOrder(item.id, {});
  };
  return (
    <div>
      <FilterSection handleFilter={handleFilter} filter={filter} />
      <MainTable
        loading={isFetching || isResending}
        sx={{
          marginTop: "40px",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Order Id</TableCell>
            <TableCell>Narration</TableCell>
            <TableCell>Order amount</TableCell>
            <TableCell>No of vouchers</TableCell>
            <TableCell>Date created</TableCell>
            <TableCell>Delivery status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.orderNumber}</TableCell>
              <TableCell>{item.narration}</TableCell>

              <TableCell style={fixedCellStyle}>{item.subTotal}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell style={fixedCellStyle}>{dateOnly(item.dateCreated)}</TableCell>
              <TableCell style={fixedCellStyle}>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell style={fixedCellStyle}>
                {item.orderTypeId === BusinessOrderType.Recipients && (
                  <>
                    <IconButton onClick={() => navigate(`${item.id}`)}>
                      <SearchIcon />
                    </IconButton>
                  </>
                )}
                <IconButton
                  onClick={() => setOrderId(item.id)}
                  disabled={![OrderStatusType.Delivered, OrderStatusType.PartiallyDelivered].includes(item.statusId)}
                >
                  <SendIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleExport(item)}
                  disabled={![OrderStatusType.Delivered, OrderStatusType.PartiallyDelivered].includes(item.statusId)}
                >
                  <RefundIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
      {orderId && (
        <EmailModal
          orderId={orderId}
          handleClose={() => {
            setOrderId(undefined);
          }}
        />
      )}
    </div>
  );
}

export default OrderListScreen;
export const fixedCellStyle: CSSProperties = { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" };
